import React from 'react';
import PropTypes from 'prop-types';
import ReactTable from 'react-table';
import 'react-table/react-table.css';

import {
  eventExplorePropTypes,
  registrationDetailPropTypes,
  userPropTypes,
} from 'helpers/proptypes';
import { EmptyComponent } from 'components/shared';
import Centered from './Centered';
import TableColumnHeaderContextMenu from './TableColumnHeaderContextMenu';
import { ActionsFormatter, StatusFormatter, SubmittedFormatter } from './formatters';

const Empty = <EmptyComponent title="No one has registered yet!" icon="fa-calendar-check-o" />;

export default class RegistrationList extends React.Component {
  static propTypes = {
    event: eventExplorePropTypes,
    registrations: PropTypes.arrayOf(registrationDetailPropTypes),
    user: userPropTypes,
    onActionClick: PropTypes.func.isRequired,
    isFacilitator: PropTypes.bool.isRequired,
    isManager: PropTypes.bool.isRequired,
    isAdmin: PropTypes.bool.isRequired,
  };

  state = {
    columns: {},
  };

  handleColumnHeaderContextMenuClick = (e, data) => {
    let column = this.state.columns[data.id] || { id: data.id };
    column = { ...column, ...data.action };
    this.setState({
      columns: {
        ...this.state.columns,
        [column.id]: { ...column },
      },
    });
  };

  render() {
    const columns = [
      {
        Header: (props) => (
          <TableColumnHeaderContextMenu
            menuId={props.column.id}
            columnId={props.column.id}
            columnHeaderName="First Name"
            onClick={this.handleColumnHeaderContextMenuClick}
          />
        ),
        id: 'firstName',
        accessor: (props) => props.name.first || props.name.rit_username,
        Cell: ({ original: row }) => (
          <Centered {...row}>{row.name.first || row.name.rit_username}</Centered>
        ),
        ...this.state.columns['firstName'],
      },
      {
        Header: (props) => (
          <TableColumnHeaderContextMenu
            menuId={props.column.id}
            columnId={props.column.id}
            columnHeaderName="Last Name"
            onClick={this.handleColumnHeaderContextMenuClick}
          />
        ),
        id: 'lastName',
        accessor: (props) => props.name.last || '-',
        Cell: ({ original: row }) => <Centered {...row}>{row.name.last || '-'}</Centered>,
        ...this.state.columns['lastName'],
      },
      {
        Header: (props) => (
          <TableColumnHeaderContextMenu
            menuId={props.column.id}
            columnId={props.column.id}
            columnHeaderName="Submitted"
            onClick={this.handleColumnHeaderContextMenuClick}
          />
        ),
        accessor: 'created',
        Cell: ({ original: row }) => <SubmittedFormatter row={row} />,
        ...this.state.columns['created'],
      },
      {
        Header: (props) => (
          <TableColumnHeaderContextMenu
            menuId={props.column.id}
            columnId={props.column.id}
            columnHeaderName="Manager"
            onClick={this.handleColumnHeaderContextMenuClick}
          />
        ),
        id: 'manager',
        accessor: (props) => (props.manager && props.manager.name) || '-',
        Cell: ({ original: row }) => (
          <Centered {...row}>{row.manager ? row.manager.name : '-'}</Centered>
        ),
        ...this.state.columns['manager'],
      },
      {
        Header: (props) => (
          <TableColumnHeaderContextMenu
            menuId={props.column.id}
            columnId={props.column.id}
            columnHeaderName="Status"
            onClick={this.handleColumnHeaderContextMenuClick}
          />
        ),
        accessor: 'status',
        Cell: ({ original: row }) => <StatusFormatter row={row} />,
        ...this.state.columns['status'],
      },
      {
        Header: (props) => (
          <TableColumnHeaderContextMenu
            menuId={props.column.id}
            columnId={props.column.id}
            columnHeaderName="Reason"
            onClick={this.handleColumnHeaderContextMenuClick}
          />
        ),
        id: 'reason',
        accessor: (props) => props.reason,
        Cell: ({ original: row }) => (
          <Centered {...row}>
            <div data-tip={row.reason}>
              <i className="fa fa-info-circle" />
            </div>
          </Centered>
        ),
        ...this.state.columns['reason'],
      },
    ];
    if (this.props.isAdmin || this.props.isManager) {
      columns.push({
        Header: 'Actions',
        id: 'actions',
        sortable: false,
        minWidth: 160,
        Cell: ({ original: row }) => (
          <ActionsFormatter
            row={row}
            user={this.props.user}
            onActionClick={this.props.onActionClick}
          />
        ),
      });
    }
    const defaultSorted = [
      {
        id: this.props.isAdmin || this.props.isManager ? 'manager' : 'lastName',
        asc: true,
      },
    ];
    return (
      <ReactTable
        className="-striped"
        data={this.props.registrations}
        columns={columns}
        defaultSorted={defaultSorted}
        noDataText={Empty}
        pageSize={this.props.registrations.length === 0 ? 6 : this.props.registrations.length} // empty component needs some table row space to render in
        showPagination={false}
      />
    );
  }
}

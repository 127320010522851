import React from 'react';
import PropTypes from 'prop-types';

const PendingRegistrationsLabel = ({ pendingCount }) => {
  switch (true) {
    case pendingCount === 1:
      return (
        <div className="help-block">There is currently {pendingCount} pending registration.</div>
      );
    case pendingCount > 1:
      return (
        <div className="help-block">There are currently {pendingCount} pending registrations.</div>
      );
    default:
      return <div className="mb2" />;
  }
};

PendingRegistrationsLabel.propTypes = {
  pendingCount: PropTypes.number.isRequired,
};

export default PendingRegistrationsLabel;

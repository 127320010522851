import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import ReactRouterPropTypes from 'react-router-prop-types';
import ReactTooltip from 'react-tooltip';

import {
  registrationManagerApprove,
  registrationDeny,
  registrationAccept,
  registrationListThunk,
} from 'store/actions/registrations';
import {
  errorPropType,
  eventExplorePropTypes,
  registrationDetailPropTypes,
  userPropTypes,
} from 'helpers/proptypes';
import { Loading, PageHeader } from 'components';
import { ErrorPage, MessageModal } from 'components/shared';
import { getVisibleRegistrations } from './selectors';
import countRegistrations from './registrations-count';
import RegistrationList from './RegistrationList';
import ProgressBar from './ProgressBar';
import CapacityLabel from './CapacityLabel';
import PendingRegistrationsLabel from './PendingRegistrationsLabel';

export class RegistrationListPage extends React.Component {
  static propTypes = {
    match: ReactRouterPropTypes.match.isRequired,
    isLoading: PropTypes.bool.isRequired,
    user: userPropTypes,
    event: eventExplorePropTypes,
    registrations: PropTypes.arrayOf(registrationDetailPropTypes),
    registrationAccept: PropTypes.func.isRequired,
    registrationManagerApprove: PropTypes.func.isRequired,
    registrationDeny: PropTypes.func.isRequired,
    registrationListThunk: PropTypes.func.isRequired,
    error: errorPropType.isRequired,
  };

  state = {
    modalOpen: false,
    modalMessage: '',
    registrationPk: undefined,
    redirect: false,
  };

  componentDidMount() {
    this.props.registrationListThunk(this.props.match.params.eventId);
  }

  componentDidUpdate() {
    ReactTooltip.rebuild();
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (this.props.isLoading === true && nextProps.isLoading === true) {
      return false;
    }
    return true;
  }

  handleMessageModalSubmit = () => {
    this.handleMessageModalClose();
    this.props.registrationDeny(this.state.registrationPk, { message: this.state.modalMessage });
    this.setState({ modalMessage: '' });
  };

  handleMessageModalClose = () => this.setState({ modalOpen: false });

  handleMessageModalInputChange = (e) => this.setState({ modalMessage: e.target.value });

  handleRegistrationActionButtonClick = (props) => {
    switch (props.type) {
      case 'accept':
        this.props.registrationAccept(props.pk);
        break;
      case 'approve':
        this.props.registrationManagerApprove(props.pk);
        break;
      case 'deny':
        this.setState({ modalOpen: true, registrationPk: props.pk });
        break;
      default:
        break;
    }
  };

  handleRegistrationInfoButtonClick = (props) => {
    this.setState({ redirect: true, registrationPk: props.pk });
  };

  render() {
    if (this.props.isLoading) {
      return <Loading />;
    }
    if (this.props.error) {
      return (
        <ErrorPage
          error={this.props.error}
          onReloadClick={() => this.props.registrationListThunk(this.props.match.params.eventId)}
        />
      );
    }
    if (this.state.redirect) {
      return (
        <Redirect
          push
          to={`/events/${this.props.event.pk}/registration-list/${this.state.registrationPk}`}
        />
      );
    }
    const isAdmin = !!this.props.user.groups.find((group) => group.name === 'admins');
    const isManager = !!this.props.user.groups.find((group) => group.name === 'managers');
    const isFacilitator = !!this.props.event.facilitators.find((pk) => pk === this.props.user.pk);
    const registrationCounts = countRegistrations(this.props.registrations);
    const seatsOpen = this.props.event.capacity - registrationCounts.accepted;
    return (
      <Fragment>
        <div className="col-xs-12 white-soft-box2 white-soft-box2--no-animate">
          <PageHeader.Basic title="Registration List" subtitle={this.props.event.title} />
          <CapacityLabel capacity={this.props.event.capacity} seatsOpen={seatsOpen} />
          <PendingRegistrationsLabel pendingCount={registrationCounts.pending} />
          <ProgressBar
            registrationCounts={registrationCounts}
            capacity={this.props.event.capacity}
          />
          <RegistrationList
            registrations={this.props.registrations}
            onActionClick={this.handleRegistrationActionButtonClick}
            // onInfoClick={this.handleRegistrationInfoButtonClick}
            user={this.props.user}
            isFacilitator={isFacilitator}
            isManager={isManager}
            isAdmin={isAdmin}
          />
        </div>
        <MessageModal
          modalOpen={this.state.modalOpen}
          closeModal={this.handleMessageModalClose}
          onInputChange={this.handleMessageModalInputChange}
          closeAndSubmit={this.handleMessageModalSubmit}
          modalTitle={'Denial Message'}
          inputValue={this.state.modalMessage}
        />
        <ReactTooltip effect="solid" type="dark" delayShow={500} globalEventOff="click" multiline />
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    error: state.eventsDetail.error
      ? state.eventsDetail.error
      : state.registrations.error
      ? state.registrations.error
      : false,
    isLoading:
      state.accountsUser.isLoading ||
      state.eventsDetail.isLoading ||
      state.registrations.listLoading,
    user: state.accountsUser[state.accountsUser.current],
    registrations: getVisibleRegistrations(state),
    event: state.eventsDetail.event,
  };
};

export default connect(
  mapStateToProps,
  {
    registrationManagerApprove,
    registrationDeny,
    registrationAccept,
    registrationListThunk,
  }
)(RegistrationListPage);

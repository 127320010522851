import React from 'react';
import PropTypes from 'prop-types';

const Centered = (props) => <span className="flex-center">{props.children}</span>;

Centered.propTypes = {
  children: PropTypes.node,
};

export default Centered;

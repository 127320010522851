import React from 'react';
import PropTypes from 'prop-types';

import { Alert } from 'components';

const ProgressBar = (props) => {
  if (props.capacity === 0) {
    return null;
  }
  const seatsOpen = props.capacity - props.registrationCounts.accepted;
  const overflowCount = props.registrationCounts.manager - seatsOpen;
  const seatsOpenPercent = ((seatsOpen / props.capacity) * 100).toFixed(0);
  const successPercent = (100 - seatsOpenPercent).toFixed(0);
  const overflowAlert =
    overflowCount > 0 ? (
      <Alert type="warning" icon="fa-exclamation-triangle">
        <span className="bold">{overflowCount} Overflow</span> (More Manager Approvals than
        Available Seats)
      </Alert>
    ) : null;
  return (
    <div>
      <div className="progress">
        <div
          className="progress-bar progress-bar-info progress-bar-striped"
          style={{ width: `${seatsOpenPercent}%` }}
        >
          {seatsOpenPercent}% Seats Open ({seatsOpen})
          <span className="sr-only">
            {seatsOpenPercent}% Seats Open ({seatsOpen})
          </span>
        </div>
        <div
          className="progress-bar progress-bar-success progress-bar-striped"
          style={{ width: `${successPercent}%` }}
        >
          {successPercent}% Seats Filled ({props.registrationCounts.accepted})
          <span className="sr-only">
            {successPercent}% Seats Filled ({props.registrationCounts.accepted})
          </span>
        </div>
      </div>
      {overflowAlert}
    </div>
  );
};

ProgressBar.propTypes = {
  capacity: PropTypes.number.isRequired,
  registrationCounts: PropTypes.shape({
    pending: PropTypes.number.isRequired,
    manager: PropTypes.number.isRequired,
    accepted: PropTypes.number.isRequired,
  }).isRequired,
};

export default ProgressBar;

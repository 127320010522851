import React from 'react';
import PropTypes from 'prop-types';
import { ContextMenu, ContextMenuTrigger, MenuItem } from 'react-contextmenu';

export default class TableColumnHeaderContextMenu extends React.Component {
  static propTypes = {
    onClick: PropTypes.func.isRequired,
    columnId: PropTypes.string.isRequired,
    columnHeaderName: PropTypes.string.isRequired,
    menuId: PropTypes.string.isRequired,
  };

  handleClick = (e, data) => this.props.onClick(e, data);

  render() {
    const hideData = {
      id: this.props.columnId,
      action: { show: false },
    };
    return (
      <div>
        <ContextMenuTrigger id={this.props.menuId}>
          {this.props.columnHeaderName}
        </ContextMenuTrigger>
        <ContextMenu id={this.props.menuId}>
          <MenuItem onClick={this.handleClick} data={hideData}>
            <span className="fa fa-fw fa-eye-slash icon-space-r" />
            Hide {this.props.columnHeaderName} Column
          </MenuItem>
        </ContextMenu>
      </div>
    );
  }
}

import { createSelector } from 'reselect';
import { EventDictionary } from 'helpers/dictionaries';

const { REG_STATUS } = EventDictionary;

export const getAuthenticatedUser = (state) => state.accountsUser[state.accountsUser.current];
export const getRegistrationList = (state) => state.registrations.list;

export const getVisibleRegistrations = createSelector(
  [getAuthenticatedUser, getRegistrationList],
  (authenticatedUser, registrations) => {
    if (!authenticatedUser) {
      return registrations;
    }
    const isManager = !!authenticatedUser.groups.find((group) => group.name === 'managers');
    const isAdmin = !!authenticatedUser.groups.find((group) => group.name === 'admins');
    if (isManager || isAdmin) {
      return registrations;
    }
    return registrations.filter((reg) =>
      [REG_STATUS.APPROVED, REG_STATUS.FACILITATOR, REG_STATUS.PENDING].includes(reg.status)
    );
  }
);

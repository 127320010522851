import React from 'react';
import PropTypes from 'prop-types';

const CapacityLabel = (props) => {
  const seatsFilled = props.capacity - props.seatsOpen;
  return (
    <div className="mt2">
      <strong className="publication-subtitle">
        Capacity: {seatsFilled}/{props.capacity}
      </strong>
    </div>
  );
};

CapacityLabel.propTypes = {
  capacity: PropTypes.number.isRequired,
  seatsOpen: PropTypes.number.isRequired,
};

export default CapacityLabel;

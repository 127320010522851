import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import { ActionButton, RegistrationStatusLabel } from 'components';
import { EventDictionary } from 'helpers/dictionaries';
import { registrationDetailPropTypes } from 'helpers/proptypes';

import Centered from './Centered';

const { REG_STATUS } = EventDictionary;

export const StatusFormatter = ({ row }) => (
  <Centered>
    <RegistrationStatusLabel status={row.status} />
  </Centered>
);

StatusFormatter.propTypes = {
  row: registrationDetailPropTypes,
};

export const SubmittedFormatter = ({ row }) => (
  <Centered>{moment(row.created).format('YYYY-MM-DD h:mm a')}</Centered>
);

SubmittedFormatter.propTypes = {
  row: registrationDetailPropTypes,
};

const actionButtonProps = {
  accept: {
    className: 'btn-outline-primary',
    icon: 'fa-fw fa-plus',
    type: 'accept',
    dataTip: 'Fully Approves Registration and fills a seat.',
  },
  approve: {
    className: 'btn-outline-success',
    icon: 'fa-fw fa-check',
    type: 'approve',
    dataTip: "Approves Registration, seat won't be filled until PDP Sponsor accepts registration.",
  },
  deny: {
    className: 'btn-outline-danger',
    icon: 'fa-fw fa-times',
    type: 'deny',
    dataTip:
      'Denies Registration Request, employee will be notified via email the following morning.',
  },
  // info: {
  //   className: 'btn-outline-info',
  //   icon: 'fa-fw fa-info',
  //   type: 'info',
  //   dataTip: 'View Event info.',
  // },
};

export const ActionsFormatter = ({ row, user, onActionClick }) => {
  // Add back in info to initial buttons { ...actionButtonProps.info, pk: row.id, onClick: onInfoClick }
  const buttons = [];
  const isAdmin = user.groups.find((group) => group.name === 'admins');
  if (isAdmin) {
    if (row.status === REG_STATUS.APPROVED_PENDING_REVIEW) {
      buttons.push({ ...actionButtonProps.accept, pk: row.id, onClick: onActionClick });
    }
  }
  if ((row.manager && row.manager.pk === user.pk) || isAdmin) {
    if ([REG_STATUS.DENIED, REG_STATUS.PENDING].includes(row.status)) {
      buttons.push({ ...actionButtonProps.approve, pk: row.id, onClick: onActionClick });
    }
    if ([REG_STATUS.APPROVED_PENDING_REVIEW, REG_STATUS.PENDING].includes(row.status)) {
      buttons.push({ ...actionButtonProps.deny, pk: row.id, onClick: onActionClick });
    }
    // if ([REG_STATUS.APPROVED_PENDING_REVIEW, REG_STATUS.PENDING].includes(row.status)) {
    //   buttons.push({ ...actionButtonProps.info, pk: row.id, onClick: onInfoClick });
    // }
  }
  return (
    <Centered>
      {buttons.map((btn, idx) => (
        <span key={idx} className="icon-space-r">
          <ActionButton {...btn} />
        </span>
      ))}
    </Centered>
  );
};

ActionsFormatter.propTypes = {
  row: registrationDetailPropTypes.isRequired,
  user: PropTypes.object.isRequired,
  onActionClick: PropTypes.func.isRequired,
  // onInfoClick: PropTypes.func.isRequired,
};

import { EventDictionary } from 'helpers/dictionaries';

const { REG_STATUS } = EventDictionary;

export default (registrations) => {
  let pending = 0;
  let manager = 0;
  let accepted = 0;
  registrations.forEach((reg) => {
    if (reg.status === REG_STATUS.APPROVED) {
      accepted++;
    }
    if (reg.status === REG_STATUS.APPROVED_PENDING_REVIEW) {
      manager++;
    }
    if (reg.status === REG_STATUS.PENDING) {
      pending++;
    }
  });
  return {
    pending,
    manager,
    accepted,
  };
};
